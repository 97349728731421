/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2024-01-25 15:29:54
 */

import {isProd} from 'ssr-common/constant/config';

import BusinessEvents from 'easycash/common/constant/business-events';
import {EditMobileUploadInfoPageEnter} from 'easycash/common/constant/business-events/edit-mobile';
import {
    MergeMobilePageEnter,
    MergeMobileOtpPageEnter,
    MergeMobileResultPageEnter,
    MergeMobileUploadInfoPageEnter
} from 'easycash/common/constant/business-events/merge-mobile';

import RoutesActivity from './routes-activity';
import RoutesBiz from './routes-biz';
import RoutesLender from './routes-lender';

const OfficialWebsiteMeta = {
    business: {
        buriedPoint: true,
        uploadQuery: true,
        withBrowserInfo: true
    }
};

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('easycash/app/index-new'),
        children: [
            {
                path: '/(home)?',
                name: 'home',
                component: () => import('easycash/app/index-new/home'),
                meta: OfficialWebsiteMeta
            },
            {
                path: '/borrow',
                name: 'borrow',
                component: () => import('easycash/app/index-new/borrow'),
                meta: OfficialWebsiteMeta
            },
            {
                path: '/lend',
                name: 'lend',
                component: () => import('easycash/app/index-new/lend'),
                meta: OfficialWebsiteMeta
            },
            {
                path: '/blog',
                name: 'blog',
                component: () => import('easycash/app/index-new/blog-v2'),
                meta: OfficialWebsiteMeta
            },
            {
                path: '/blog/:blogPath',
                name: 'blogpage',
                component: () => import('easycash/app/index-new/blog-page-v2')
            },
            {
                path: '/about',
                name: 'about',
                component: () => import('easycash/app/index-new/about-us'),
                redirect: {name: 'us'},
                children: [
                    {
                        path: '/about/faq',
                        name: 'faq',
                        component: () => import('easycash/app/index-new/faq'),
                        redirect: {name: 'faqGeneral'},
                        children: [
                            {
                                path: '/about/faq/generalQuestion',
                                name: 'faqGeneral',
                                component: () => import('easycash/app/index-new/faq/generalQuestion'),
                                meta: OfficialWebsiteMeta
                            },
                            {
                                path: '/about/faq/borrow',
                                name: 'faqBorrow',
                                component: () => import('easycash/app/index-new/faq/borrow')
                            },
                            {
                                path: '/about/faq/lend',
                                name: 'faqLend',
                                component: () => import('easycash/app/index-new/faq/lend')
                            },
                            {
                                path: '/about/faq/bewareOfFraud',
                                name: 'faqBeware',
                                component: () => import('easycash/app/index-new/faq/bewareOfFraud')
                            },
                            {
                                path: '/about/faq/laps-sjk',
                                name: 'faqLapsSjk',
                                component: () => import('easycash/app/index-new/faq/lapsSjk')
                            }
                            // {
                            //     path: '/about/faq/lendermobile',
                            //     name: 'lendermobile',
                            //     component: () => import('easycash/app/index/about/faq/lendermobile')
                            // },
                            // {
                            //     path: '/about/faq/lending',
                            //     name: 'lending',
                            //     redirect: {name: 'lendingone'},
                            //     component: () => import('easycash/app/index/about/faq/lending')
                            // },
                            // {
                            //     path: '/about/faq/lendingone',
                            //     name: 'lendingone',
                            //     component: () => import('easycash/app/index/about/faq/lendingone')
                            // },
                            // {
                            //     path: '/about/faq/lendingmobile',
                            //     name: 'lendingmobile',
                            //     component: () => import('easycash/app/index/about/faq/lendingmobile')
                            // }
                        ]
                    },
                    {
                        path: '/about/privacy',
                        name: 'privacy',
                        component: () => import('easycash/app/index/about/privacy')
                    },
                    {
                        path: '/about/terms',
                        name: 'terms',
                        component: () => import('easycash/app/index/about/terms')
                    },
                    {
                        path: '/about/us',
                        name: 'us',
                        component: () => import('easycash/app/index-new/about-us'),
                        redirect: {name: 'operation'},
                        children: [
                            {
                                path: '/about/us/operation',
                                name: 'operation',
                                component: () => import('easycash/app/index-new/about-us/operation'),
                                meta: OfficialWebsiteMeta
                            },
                            {
                                path: '/about/us/management',
                                name: 'management',
                                component: () => import('easycash/app/index-new/about-us/management')
                            },
                            {
                                path: '/about/us/financial-report',
                                name: 'finacicalReport',
                                component: () => import('easycash/app/index-new/about-us/financial-report')
                            },
                            {
                                path: '/about/us/publication-complaint',
                                name: 'publicationComplaint',
                                component: () => import('easycash/app/index-new/about-us/publication-complaint')
                            },
                            {
                                path: '/about/us/activity',
                                name: 'activity',
                                component: () => import('easycash/app/index/about/us/activity')
                            },
                            {
                                path: '/about/us/social-event',
                                name: 'socialEvent',
                                component: () => import('easycash/app/index/about/us/social-event')
                            },
                            {
                                path: '/service',
                                name: 'service',
                                component: () => import('easycash/app/index/service')
                            },
                            {
                                path: '/prevent-fraud',
                                name: 'preventFraud',
                                component: () => import('easycash/app/index/about/faq/prevent-fraud')
                            }
                        ]
                    }
                ]
            },
            {
                path: '/complaint',
                name: 'complaint',
                component: () => import('easycash/app/index-new/complaint')
            }
        ]
    },
    {
        path: '/index',
        redirect: {name: 'home'}
    },
    {
        path: '/exe-team',
        name: 'executiveTeam',
        component: () => import('easycash/app/next/index/executive-team')
    },
    {
        path: '/unsubscribe/:id',
        name: 'Unsubscribe',
        component: () => import('easycash/app/index/unsubscribe')
    },
    {
        path: '/opera-data',
        name: 'operationData',
        component: () => import('easycash/app/next/index/operation-data')
    },
    {
        path: '/borrower',
        name: 'borrower',
        component: () => import('easycash/app/next/index/faq/borrower')
    },
    {
        path: '/user-service',
        name: 'userService',
        component: () => import('easycash/app/next/index/faq/user-services')
    },
    {
        path: '/complaints',
        name: 'complaints',
        component: () => import('easycash/app/next/index/faq/complaint')
    },
    ...(isProd() ? [] : [
        {
            path: '/demo',
            name: 'demo',
            component: () => import('easycash/app/demo'),
            props: true
        },
        {
            path: '/demo/face',
            name: 'DemoFace',
            component: () => import('easycash/app/demo/face')
        }
    ]),
    {
        path: '/webview',
        component: () => import('easycash/app/webview'),
        children: [
            {
                path: '/webview/agreement',
                name: 'agreement',
                component: () => import(
                    /* webpackChunkName: "ssr-stable.query-key" */
                    'easycash/app/webview/agreement'),
                meta: {
                    titleKey: 'webview.title.agreement',
                    business: {
                        enterEvent: BusinessEvents.AgreementPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/agreement/:key',
                name: 'agreementKey',
                component: () => import(
                    /* webpackChunkName: "ssr-stable.params-key" */
                    'easycash/app/webview/agreement'),
                meta: {
                    titleKey: 'webview.title.agreement',
                    business: {
                        enterEvent: BusinessEvents.AgreementPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/static-agreement/:key',
                name: 'staticAgreement',
                component: () => import(
                    /* webpackChunkName: "ssr-stable.static-params-key" */
                    'easycash/app/webview/static-agreement'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.StaticAgreementPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/static-text/:key',
                name: 'staticText',
                component: () => import(
                    /* webpackChunkName: "ssr-stable.static-text" */
                    'easycash/app/webview/static-text'
                )
            },
            {
                /* webpackChunkName: "ssr-stable.email-coupon" */
                path: '/webview/email-coupon',
                name: 'emailCoupon',
                component: () => import('easycash/app/webview/email-coupon')
            },
            {
                path: '/webview/pdf-view',
                name: 'pdfView',
                component: () => import('easycash/app/webview/pdf-view')
            },
            {
                path: '/webview/optional-info',
                name: 'optionalInfo',
                component: () => import('easycash/app/webview/optional-info'),
                meta: {
                    titleKey: 'webview.title.optionalInfo'
                }
            },
            {
                path: '/webview/profile',
                name: 'profile',
                component: () => import('easycash/app/webview/profile'),
                meta: {
                    titleKey: 'webview.title.profile'
                }
            },
            {
                path: 'questionnaire',
                name: 'questionnaire',
                component: () => import(
                    /* webpackChunkName: "ssr-stable.questionnaire" */
                    'easycash/app/webview/questionnaire')
            },
            {
                path: 'event-questionnaire/:eventType',
                name: 'eventQuestionnaire',
                component: () => import('easycash/app/webview/event-questionnaire')
            },
            {
                path: '/webview/spider/npwp',
                name: 'npwp',
                component: () => import('easycash/app/webview/spider/npwp'),
                meta: {
                    titleKey: 'webview.title.npwp'
                }
            },
            {
                path: '/webview/withdraw',
                name: 'withdraw',
                component: () => import('easycash/app/webview/withdraw'),
                meta: {
                    titleKey: 'webview.title.withdraw'
                }
            },
            {
                path: '/webview/quota',
                name: 'quota',
                component: () => import('easycash/app/webview/quota/my-quota'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/quota-change-history',
                name: 'quotaChangeHistory',
                component: () => import('easycash/app/webview/quota/quota-change-history'),
                meta: {
                    business: {
                        buriedPoint: true
                    }
                }
            },
            {
                path: '/webview/spider/bpjs',
                name: 'bpjs',
                component: () => import('easycash/app/webview/spider/bpjs'),
                meta: {
                    titleKey: 'webview.title.bpjs'
                }
            },
            {
                path: '/webview/spider/operator',
                name: 'operator',
                component: () => import('easycash/app/webview/spider/operator'),
                meta: {
                    titleKey: 'webview.title.operator'
                }
            },
            {
                path: '/webview/spider/education',
                name: 'education',
                component: () => import('easycash/app/webview/spider/education'),
                meta: {
                    titleKey: 'webview.title.education'
                }
            },
            {
                path: 'message',
                name: 'Message',
                component: () => import('easycash/app/webview/message-center/message'),
                meta: {
                    titleKey: 'webview.title.message'
                }
            },
            {
                path: 'message/:id',
                name: 'MessageDetail',
                component: () => import('easycash/app/webview/message-center/message-detail'),
                meta: {
                    titleKey: 'webview.title.message'
                }
            },
            {
                path: 'coupon-instructions',
                name: 'CouponInstructions',
                component: () => import('easycash/app/webview/coupon-instructions'),
                meta: {
                    titleKey: 'webview.title.couponInstructions'
                }
            },
            {
                path: 'loan-supermarket',
                name: 'LoanSupermarketList',
                component: () => import('easycash/app/webview/loan-supermarket/list')
            },
            {
                path: 'loan-supermarket/:id',
                name: 'LoanSupermarketDetail',
                component: () => import('easycash/app/webview/loan-supermarket/detail')
            },
            {
                path: 'reloan-accounting',
                name: 'ReloanAccounting',
                component: () => import('easycash/app/webview/reloan-accounting')
            },
            {
                path: 'pengumuman',
                name: 'Pengumuman',
                component: () => import('easycash/app/webview/pengumuman/Pengumuman')
            },
            {
                path: 'prl-example/:key',
                name: 'PRLExample',
                component: () => import('easycash/app/webview/PRL-example')
            },
            {
                path: 'activity/register-human',
                name: 'ActivityRegisterHuman',
                component: () => import('easycash/app/webview/activity/register/mgm')
            },
            {
                path: 'activity/register-human-v2',
                name: 'ActivityRegisterHumanV2',
                component: () => import('easycash/app/webview/activity/register/mgm-v2')
            },
            {
                path: 'activity/register-landing',
                name: 'ActivityRegisterLanding',
                component: () => import('easycash/app/webview/activity/register/landing')
            },
            {
                path: 'activity/comming',
                name: 'ActivityComming',
                component: () => import('easycash/app/webview/activity/comming')
            },
            {
                path: 'order-page',
                name: 'OrderPage',
                component: () => import('easycash/app/webview/order-page'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                    }
                }
            },
            {
                path: 'activity/lottery/:activityId?',
                name: 'ActivityLottery',
                component: () => import('easycash/app/webview/activity/lottery')
            },
            {
                path: 'activity/lucky-number/:activityId',
                name: 'LuckNumber',
                component: () => import('easycash/app/webview/activity/lucky-number')
            },
            {
                path: 'activity/lucky-number/reward-list/:activityId',
                name: 'LuckNumberRewardList',
                component: () => import('easycash/app/webview/activity/lucky-number/reward-list')
            },
            {
                path: 'activity/point/:activityId',
                name: 'ActivityPoint',
                component: () => import('easycash/app/webview/activity/point')
            },
            {
                path: 'activity/point-history',
                name: 'ActivityPointHistory',
                component: () => import('easycash/app/webview/activity/point/point-history')
            },
            {
                path: 'activity/point-reward-detail/:id',
                name: 'ActivityPointRewardDetail',
                component: () => import('easycash/app/webview/activity/point/reward-detail')
            },
            {
                path: 'activity/prize-wheel/:activityId',
                name: 'ActivityPrizeWheel',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.PrizeWheelPageEnter
                    }
                },
                component: () => import('easycash/app/webview/activity/prize-wheel')
            },
            {
                path: 'activity/salary-week',
                name: 'SalaryWeek',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.SalaryWeekPageEnter
                    }
                },
                component: () => import('easycash/app/webview/activity/salary-week')
            },
            {
                path: 'activity/pay-wish',
                name: 'PayWish',
                component: () => import('easycash/app/webview/activity/pay-wish')
            },
            {
                path: 'activity/pay-wish-detail',
                name: 'PayWishDetail',
                component: () => import('easycash/app/webview/activity/pay-wish/components/detail')
            },
            {
                path: 'activity/world-cup',
                name: 'WorldCup',
                component: () => import('easycash/app/webview/activity/world-cup')
            },
            {
                path: 'invitation-human',
                name: 'InvitationHuman',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.InvitationPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.invitation-human" */
                    'easycash/app/webview/invitation/human-new')
            },
            {
                path: 'invitation-human-v2',
                name: 'InvitationHumanV2',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.InvitationPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.invitation-human-v2" */
                    'easycash/app/webview/invitation/human-new-v2')
            },
            {
                path: 'invitation/detail',
                name: 'InvitationDetail',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.InvitationDetailPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import('easycash/app/webview/invitation/detail')
            },
            {
                path: 'invitation-human/detail',
                name: 'InvitationDetailHuman',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.InvitationDetailPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.invitation-human-detail" */
                    'easycash/app/webview/invitation/human-new/detail')
            },
            {
                path: 'invitation-human-v2/detail',
                name: 'InvitationDetailHumanV2',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.InvitationDetailPageEnterV2,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.invitation-human-v2-detail" */
                    'easycash/app/webview/invitation/human-new-v2/detail')
            },
            {
                path: 'wallet',
                name: 'Wallet',
                component: () => import(
                    /* webpackChunkName: "ssr-stable.wallet" */
                    'easycash/app/webview/wallet')
            },
            {
                path: 'wallet/detail',
                name: 'WalletDetail',
                component: () => import(
                    /* webpackChunkName: "ssr-stable.wallet-detail" */
                    'easycash/app/webview/wallet/detail')
            },
            {
                path: 'pdf-preview',
                name: 'MobilePdfPreview',
                component: () => import('easycash/app/index/about/us/financial-report/mobile-preview')
            },
            {
                path: 'prevent-fraud',
                name: 'PreventFraud',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.PreventFraudPageEnter
                    }
                },
                component: () => import('easycash/app/webview/prevent-fraud')
            },
            {
                path: 'how-to-loan',
                name: 'HowToLoan',
                component: () => import('easycash/app/webview/how-to-loan')
            },
            {
                path: 'how-to-loan-new',
                name: 'HowToLoanNew',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.HowToLoanNewPageEnter
                    }
                },
                component: () => import('easycash/app/webview/how-to-loan-new')
            },
            {
                path: 'additional-info',
                name: 'AdditionalInfo',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.info" */
                    'easycash/app/webview/additional-info/index-new')
            },
            {
                path: 'additional-info/bind-bank',
                name: 'AdditionalInfoBindBank',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.bind-bank" */
                    'easycash/app/webview/additional-info/bind-bank')
            },
            {
                path: 'additional-info/confirm-bank',
                name: 'AdditionalInfoConfirmBank',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.confirm-bank" */
                    'easycash/app/webview/additional-info/bind-bank/confirm-bank')
            },
            {
                path: 'additional-info/bind-ecom',
                name: 'AdditionalInfoBindEcom',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.bind-ecom" */
                    'easycash/app/webview/additional-info/bind-ecom')
            },
            {
                path: 'additional-info/confirm-ecom',
                name: 'AdditionalInfoConfirmEcom',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.confirm-ecom" */
                    'easycash/app/webview/additional-info/bind-ecom/confirm-ecom')
            },
            {
                path: 'additional-info/upload/:type',
                name: 'AdditionalInfoUpload',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.AdditionalInfoUploadPageEnter
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.upload-type" */
                    'easycash/app/webview/additional-info/upload')
            },
            {
                path: 'additional-info/contact-upload',
                name: 'AdditionalInfoUploadContact',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.contact-upload" */
                    'easycash/app/webview/additional-info/contact')
            },
            {
                path: 'additional-info/competitors',
                name: 'UploadCompetitors',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.competitors" */
                    'easycash/app/webview/additional-info/competitors')
            },
            {
                path: 'additional-info/input',
                name: 'AdditionalInfoInputPage',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.AdditionalInfoInputPageEnter
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-activity.input-page" */
                    'easycash/app/webview/additional-info/input-page')
            },
            {
                path: 'full-video',
                name: 'FullVideo',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import('easycash/app/webview/full-video')
            },
            {
                path: 'tied-card-guide',
                name: 'TiedCardGuide',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.TiedCardGuidePageEnter
                    }
                },
                component: () => import(
                    /* webpackChunkName: "ssr-stable.tied-card-guide" */
                    'easycash/app/webview/tied-card-guide')
            },
            {
                path: 'agreement-pdf',
                name: 'AgreementPdf',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.AgreementPdfPageEnter
                    }
                },
                component: () => import('easycash/app/webview/agreement-pdf')
            },
            {
                path: 'customer-service',
                redirect: {name: 'CustomerService'},
                component: () => import('easycash/app/webview/customer-service')
            },
            {
                path: 'help-center',
                name: 'CustomerService',
                component: () => import('easycash/app/webview/customer-service')
            },
            {
                path: 'customer-service/faq',
                name: 'CustomerServiceFaq',
                component: () => import('easycash/app/webview/customer-service/faq')
            },
            {
                path: 'customer-service/complaint',
                name: 'CustomerServiceComplaint',
                component: () => import('easycash/app/webview/customer-service/complaint')
            },
            {
                path: 'privyid-faq',
                name: 'PrivyIDFaq',
                component: () => import('easycash/app/webview/privyid-faq')
            },
            {
                path: 'renewal-instructions',
                name: 'RenewalInstructions',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.RenewalInstructionsPageEnter
                    }
                },
                component: () => import('easycash/app/webview/renewal-instructions')
            },
            {
                path: 'activity/call-back',
                name: 'ActivityCallBack',
                component: () => import('easycash/app/webview/activity/call-back')
            },
            {
                path: 'logout',
                name: 'Logout',
                component: () => import('easycash/app/webview/logout-page')
            },
            {
                path: 'close',
                name: 'Close',
                component: () => import('easycash/app/webview/close')
            },
            {
                path: 'test-bridge',
                name: 'TestBridge',
                component: () => import('easycash/app/webview/test-bridge')
            },
            {
                path: 'mother-surname',
                name: 'MotherSurname',
                component: () => import('easycash/app/webview/mother-surname')
            },
            {
                path: 'loan-edit-mobile',
                name: 'LoanEditMobile',
                component: () => import('easycash/app/webview/loan-edit-mobile'),
                children: [
                    {
                        path: 'edit-mobile',
                        name: 'EditMobile',
                        component: () => import('easycash/app/webview/loan-edit-mobile/edit-mobile')
                    },
                    {
                        path: 'identity-number',
                        name: 'IdentityNumber',
                        component: () => import('easycash/app/webview/loan-edit-mobile/identity-number')
                    },
                    {
                        path: 'new-mobile',
                        name: 'NewMobile',
                        component: () => import('easycash/app/webview/loan-edit-mobile/new-mobile')
                    },
                    {
                        path: 'new-mobile-otp',
                        name: 'NewMobileOtp',
                        component: () => import('easycash/app/webview/loan-edit-mobile/new-mobile-otp')
                    },
                    {
                        path: 'edit-upload-info',
                        name: 'EditUploadInfo',
                        meta: {
                            business: {
                                enterEvent: EditMobileUploadInfoPageEnter,
                                uploadQuery: true
                            }
                        },
                        component: () => import('easycash/app/webview/merge-mobile/merge-upload-info')
                    },
                    {
                        path: 'edit-result',
                        name: 'EditResult',
                        component: () => import('easycash/app/webview/loan-edit-mobile/edit-result')
                    }
                ]
            },
            {
                path: 'img-page',
                name: 'ImgPage',
                component: () => import('easycash/app/webview/img-page'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.ImgPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'merge-mobile',
                name: 'MergeMobile',
                meta: {
                    business: {
                        enterEvent: MergeMobilePageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import('easycash/app/webview/merge-mobile')
            },
            {
                path: 'merge-mobile-otp',
                name: 'MergeMobileOtp',
                meta: {
                    business: {
                        enterEvent: MergeMobileOtpPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import('easycash/app/webview/merge-mobile/check-otp')
            },
            {
                path: 'merge-mobile-upload-info',
                name: 'MergeMobileUploadInfo',
                meta: {
                    business: {
                        enterEvent: MergeMobileUploadInfoPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import('easycash/app/webview/merge-mobile/merge-upload-info')
            },
            {
                path: 'merge-mobile-result',
                name: 'MergeMobileResult',
                meta: {
                    business: {
                        enterEvent: MergeMobileResultPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import('easycash/app/webview/merge-mobile/merge-mobile-result')
            },
            {
                path: 'merchant-info',
                name: 'MerchantInfo',
                component: () => import('easycash/app/webview/merchant-info')
            },
            {
                path: 'merchant-detail',
                name: 'MerchantDetail',
                component: () => import('easycash/app/webview/merchant-info/detail')
            },
            {
                path: 'ovo',
                name: 'ovo',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.OvoPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import('easycash/app/webview/ovo')
            },
            {
                path: 'activity/new-year-activity',
                name: 'NewYearActivity',
                component: () => import('easycash/app/webview/activity/new-year-activity'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.NewYearActivityPageEnter
                    }
                }
            },
            {
                path: 'activity/winners',
                name: 'Winners',
                component: () => import('easycash/app/webview/activity/new-year-activity/components/winner-table'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.NewYearActivityWinnersPageEnter
                    }
                }
            },
            {
                path: 'protocol',
                name: 'protocol',
                component: () => import('easycash/app/webview/protocol'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.ProtocolPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'activity/valentine',
                name: 'valentine',
                component: () => import('easycash/app/webview/lucky-valentine'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.ValentinePageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'activity/valentine/winners',
                name: 'valentineWinners',
                component: () => import('easycash/app/webview/lucky-valentine/components/list'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.ValentineWinnersPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'additional-again',
                name: 'AdditionalAgain',
                component: () => import(
                    /* webpackChunkName: "ssr-activity.again-index" */
                    'easycash/app/webview/additional-again')
            },
            {
                path: 'additional-again/upload/:type',
                name: 'AdditionalAgainUpload',
                component: () => import(
                    /* webpackChunkName: "ssr-activity.again-upload" */
                    'easycash/app/webview/additional-again/upload')
            },
            {
                path: 'ojk-checkuser',
                name: 'OjkCheckUser',
                component: () => import('easycash/app/webview/ojk-checkuser')
            },
            {
                path: 'activity-center',
                name: 'ActivityCenter',
                component: () => import('easycash/app/webview/activity-center'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'social-media',
                name: 'social-media',
                component: () => import('easycash/app/webview/social-media'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserKey: true
                    }
                }
            },
            {
                path: 'finantier-register',
                name: 'finantier-register',
                component: () => import('easycash/app/webview/finantier-register'),
                meta: {
                    titleKey: 'webview.title.finantier'
                }
            },
            {
                path: 'finantier-agreement',
                name: 'finantier-agreement',
                component: () => import('easycash/app/webview/finantier-register/agreement'),
                meta: {
                    titleKey: 'webview.title.finantier'
                }
            },
            {
                path: 'finantier-link-bank',
                name: 'finantier-link-bank',
                component: () => import('easycash/app/webview/finantier-register/link-bank'),
                meta: {
                    titleKey: 'webview.title.finantier'
                }
            },
            {
                path: 'activity/lift-lottery/:activityId',
                name: 'LiftLottery',
                component: () => import('easycash/app/webview/activity/lift-lottery'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/lift-lottery/:activityId/rule',
                name: 'LiftLotteryRule',
                component: () => import('easycash/app/webview/activity/lift-lottery/rule'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/lift-lottery/:activityId/history',
                name: 'LiftLotteryHistory',
                component: () => import('easycash/app/webview/activity/lift-lottery/history'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/lift-lottery/:activityId/record',
                name: 'LiftLotteryRecord',
                component: () => import('easycash/app/webview/activity/lift-lottery/record'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/toy-egg/:activityId',
                name: 'ToyEgg',
                component: () => import('easycash/app/webview/activity/toy-egg'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/toy-egg-2/:activityId',
                name: 'ToyEgg2',
                component: () => import('easycash/app/webview/activity/toy-egg-2'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/toy-egg-2/policyAgreement/:activityId',
                name: 'PolicyAgreement',
                component: () => import('easycash/app/webview/activity/toy-egg-2/policy-agreement'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/six-year',
                name: 'SixYearActivity',
                component: () => import('easycash/app/webview/activity/six-year'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/sign-in',
                name: 'SignIn',
                component: () => import('easycash/app/webview/activity/sign-in'),
            },
            {
                path: 'activity/sign-in-order',
                name: 'SignInOrder',
                component: () => import('easycash/app/webview/activity/sign-in/order'),
            },
            {
                path: 'activity/six-year/rule',
                name: 'SixYearActivityRule',
                component: () => import('easycash/app/webview/activity/six-year/rule'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'new-gift',
                name: 'NewGift',
                component: () => import('easycash/app/webview/anew-h5/new-gift'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'order-gift',
                name: 'OrderGift',
                component: () => import('easycash/app/webview/anew-h5/order-gift'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/watering',
                name: 'Watering',
                component: () => import(
                    /* webpackChunkName: "ssr-activity.old-index" */
                    'easycash/app/webview/anew-h5/water'),
                meta: {
                    business: {
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/watering/rule',
                name: 'WateringRule',
                component: () => import(
                    /* webpackChunkName: "ssr-activity.old-rule" */
                    'easycash/app/webview/anew-h5/water/rule.vue'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/watering-new',
                name: 'WateringNew',
                component: () => import(
                    /* webpackChunkName: "ssr-activity.new-index" */
                    'easycash/app/webview/anew-h5/water-new'),
                meta: {
                    business: {
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/watering-new/rule',
                name: 'WateringNewRule',
                component: () => import(
                    /* webpackChunkName: "ssr-activity.new-rule" */
                    'easycash/app/webview/anew-h5/water-new/rule.vue'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserStatus: true
                    }
                }
            },
            {
                path: 'activity/get-coupon',
                name: 'getCoupon',
                component: () => import('easycash/app/webview/activity/get-coupon'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'e-wallet-explain',
                name: 'EWalletExplain',
                component: () => import('easycash/app/webview/e-wallet-explain'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'upgrade-plus',
                name: 'UpgradePlus',
                component: () => import('easycash/app/webview/upgrade-plus'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'activity/coupon-rule',
                name: 'couponRule',
                component: () => import('easycash/app/webview/activity/get-coupon/rule'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'activity/cash-back',
                name: 'cash-back',
                component: () => import('easycash/app/webview/activity/cash-back'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.CashBackPageEnter
                    }
                }
            },
            {
                path: 'activity/lasser-bairam',
                name: 'lasser-bairam',
                component: () => import('easycash/app/webview/activity/lasser-bairam'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.LasserBairamPageEnter
                    }
                }
            },
            {
                path: 'activity/lasser-bairam/winners',
                name: 'lasserBairamWinners',
                component: () => import('easycash/app/webview/activity/lasser-bairam/components/list'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.LasserBairamWinnersPageEnter
                    }
                }
            },
            {
                path: 'activity/lucky-break/:activityId?',
                name: 'luckyBreak',
                component: () => import('easycash/app/webview/activity/lucky-break'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'activity/independence',
                name: 'Independence',
                component: () => import('easycash/app/webview/activity/independence'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserKey: true
                    }
                }
            },
            {
                path: 'activity/independence/winners',
                name: 'IndependenceWinners',
                component: () => import('easycash/app/webview/activity/independence/detail'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        withUserKey: true
                    }
                }
            },
            {
                path: 'activity/tomoro/:activityId',
                name: 'TomoroCoffee',
                component: () => import('easycash/app/webview/activity/tomoro-coffee')
            },
            {
                path: 'activity/tomoro-rules/:activityId',
                name: 'TomoroCoffeeRules',
                component: () => import('easycash/app/webview/activity/tomoro-coffee/rules')
            },
            {
                path: 'survey',
                name: 'Survey',
                component: () => import(
                    /* webpackChunkName: "ssr-stable.survey" */
                    'easycash/app/webview/survey'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'survey-success',
                name: 'SurveySuccess',
                component: () => import(
                    /* webpackChunkName: "ssr-stable.survey-success" */
                    'easycash/app/webview/survey/success'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            ...RoutesBiz,
            ...RoutesActivity,
            ...RoutesLender
        ]
    },
    {
        path: '/repayment/success',
        name: 'repaymentSuccess',
        component: () => import(
            /* webpackChunkName: "ssr-stable.repayment-success" */
            'easycash/app/webview/repayment-success')
    },
    {
        path: '/error-page',
        name: 'ErrorPage',
        component: () => import('easycash/app/error')
    },
    {
        path: '*',
        component: () => import('easycash/app/not-found')
    }
];

const routesToMap = (routeArr, map = {}) => {
    routeArr.forEach(route => {
        const {children, name, ...rest} = route;
        if (name) map[name] = {name, ...rest};
        if (children) routesToMap(children, map);
    });

    return map;
};

export const routeMap = routesToMap(routes);

export default routes;
